var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tabs',{attrs:{"centered":""},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-tab',[_vm._v("Complete")]),_c('v-tab',[_vm._v("Candidates")]),_c('v-tab',[_vm._v("In Progress")])],1),_c('v-tabs-items',{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-tab-item',{key:1},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","offset-md":"8","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":_vm.icons.mdiMagnify,"label":"Search","single-line":"","hide-details":"","dense":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{key:_vm.completeTabKey,staticClass:"table-kitchen-sink",attrs:{"loading":_vm.loadingComplete,"search":_vm.search,"headers":_vm.completedHeaders,"items":_vm.completedItems,"sort-by":['modifiedAt'],"sort-desc":['true']},scopedSlots:_vm._u([{key:"item.amendCompleted",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"me-3",attrs:{"color":"success","medium":""},on:{"click":function($event){return _vm.loadPatientProfile(item['patientID'], item['_id'])}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])]}},_vm._l((_vm.inProgressHeaders.filter(function (header) { return header.hasOwnProperty('formatter'); }
            )),function(header){return {key:("item." + (header.value)),fn:function(ref){
            var header = ref.header;
            var value = ref.value;
return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}}),{key:"item.duplicateCompleted",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{staticClass:"me-3",attrs:{"medium":""},on:{"click":function($event){return _vm.duplicate(item['patientID'], item['_id'])}}},[_vm._v(" "+_vm._s(_vm.icons.mdiContentDuplicate)+" ")])]}},{key:"item.dlPDF",fn:function(ref){
            var item = ref.item;
            var index = ref.index;
return [_c('v-icon',{staticClass:"me-3",attrs:{"color":"primary","medium":""},on:{"click":function($event){return _vm.downloadPdf(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiCloudDownload)+" ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reset ")])]},proxy:true},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary","outlined":"","pill":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}}],null,true)})],1)],1),_c('v-tab-item',{key:2},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"md":"4"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.loadCandidatePatientProfile()}}},[_vm._v(" Start medscheck ")])],1),_c('v-col',{attrs:{"md":"4","offset-md":"4"}},[_c('v-text-field',{attrs:{"append-icon":_vm.icons.mdiMagnify,"label":"Search","single-line":"","hide-details":"","dense":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{key:_vm.refreshCandidateList,staticClass:"table-kitchen-sink",attrs:{"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"show-expand":"","loading":_vm.loading,"show-select":"","search":_vm.search,"headers":_vm.candidateHeaders,"items":_vm.candidatesList,"item-key":"PatientID","single-select":_vm.singleSelect},on:{"update:expanded":function($event){_vm.expanded=$event},"item-expanded":_vm.getSearchByRx},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
            var headers = ref.headers;
            var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"table-kitchen-sink",attrs:{"headers":_vm.drugListHeaders,"items":_vm.detailedMedicationsList,"search":_vm.search,"items-per-page":15,"item-key":"RxNumber"},scopedSlots:_vm._u([_vm._l((_vm.drugListHeaders.filter(function (header) { return header.hasOwnProperty('formatter'); }
                      )),function(header){return {key:("item." + (header.value)),fn:function(ref){
                      var header = ref.header;
                      var value = ref.value;
return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}})],null,true)})],1)],1)],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_c('v-tab-item',{key:3},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","offset-md":"8","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":_vm.icons.mdiMagnify,"label":"Search","single-line":"","hide-details":"","dense":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{staticClass:"table-kitchen-sink",attrs:{"loading":_vm.loadingInProcess,"search":_vm.search,"headers":_vm.inProgressHeaders,"item-key":"_id","items":_vm.inProgressItems,"sort-by":['modifiedAt'],"sort-desc":['true']},scopedSlots:_vm._u([_vm._l((_vm.inProgressHeaders.filter(function (header) { return header.hasOwnProperty('formatter'); }
            )),function(header){return {key:("item." + (header.value)),fn:function(ref){
            var header = ref.header;
            var value = ref.value;
return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}}),{key:"item.amendInProgress",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{staticClass:"me-3",attrs:{"color":"success","medium":""},on:{"click":function($event){return _vm.loadPatientProfile(item['patientID'], item['_id'])}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])]}},{key:"item.delete",fn:function(ref){
            var item = ref.item;
            var index = ref.index;
return [_c('v-icon',{staticClass:"me-3",attrs:{"color":"error","medium":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reset ")])]},proxy:true},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary","outlined":"","pill":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}}],null,true)})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Are you sure you want to delete this item? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":_vm.closeDelete}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.deleteItemConfirm}},[_vm._v(" OK ")]),_c('v-spacer')],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.Deleteconfirm),callback:function ($$v) {_vm.Deleteconfirm=$$v},expression:"Deleteconfirm"}},[_c('v-alert',{attrs:{"type":"warning"}},[_vm._v(" In Progress Medscheck Deleted Successfully ")])],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.loadingAmend),callback:function ($$v) {_vm.loadingAmend=$$v},expression:"loadingAmend"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',{staticClass:"pt-3"},[_vm._v(" Please stand by "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }