<template>
  <div>
    <v-tabs
      v-model="currentTab"
      centered
    >
      <v-tab>Complete</v-tab>
      <v-tab>Candidates</v-tab>
      <v-tab>In Progress</v-tab>
    </v-tabs>
    <v-tabs-items v-model="currentTab">
      <v-tab-item :key="1">
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                offset-md="8"
                md="4"
              >
                <v-text-field
                  v-model="search"
                  :append-icon="icons.mdiMagnify"
                  label="Search"
                  single-line
                  hide-details
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-data-table
            :key="completeTabKey"
            :loading="loadingComplete"
            class="table-kitchen-sink"
            :search="search"
            :headers="completedHeaders"
            :items="completedItems"
            :sort-by="['modifiedAt']"
            :sort-desc="['true']"
          >
            <!-- action -->
            <template
              #[`item.amendCompleted`]="{ item }"
              :loading="loadingAmend"
            >
              <v-icon
                color="success"
                medium
                class="me-3"
                @click="loadPatientProfile(item['patientID'], item['_id'])"
              >
                {{ icons.mdiPencilOutline }}
              </v-icon>
            </template>
            <template
              v-for="header in inProgressHeaders.filter((header) =>
                header.hasOwnProperty('formatter')
              )"
              v-slot:[`item.${header.value}`]="{ header, value }"
            >
              {{ header.formatter(value) }}
            </template>
            <template #[`item.duplicateCompleted`]="{ item }">
              <v-icon
                medium
                class="me-3"
                @click="duplicate(item['patientID'], item['_id'])"
              >
                {{ icons.mdiContentDuplicate }}
              </v-icon>
            </template>
            <template #[`item.dlPDF`]="{ item, index }">
              <v-icon
                color="primary"
                medium
                class="me-3"
                @click="downloadPdf(item)"
              >
                {{ icons.mdiCloudDownload }}
              </v-icon>
            </template>
            <template v-slot:no-data>
              <v-btn
                color="primary"
                @click="initialize"
              >
                Reset
              </v-btn>
            </template>
            <!-- status -->
            <template #[`item.status`]="{item}">
              <v-chip
                color="primary"
                outlined
                pill
              >
                {{ item.status }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>

      <v-tab-item :key="2">
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col md="4">
                <v-btn
                  color="primary"
                  @click="loadCandidatePatientProfile()"
                >
                  Start medscheck
                </v-btn>
              </v-col>
              <v-col
                md="4"
                offset-md="4"
              >
                <v-text-field
                  v-model="search"
                  :append-icon="icons.mdiMagnify"
                  label="Search"
                  single-line
                  hide-details
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <!-- <v-data-table :loading="loading" v-model="selected" show-select class="table-kitchen-sink" :search="search" :headers="candidateHeaders" :items="candidatesList" item-key="PatientID" :single-select="singleSelect"> -->
          <v-data-table
            :key="refreshCandidateList"
            v-model="selected"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            show-expand
            :loading="loading"
            show-select
            class="table-kitchen-sink"
            :search="search"
            :headers="candidateHeaders"
            :items="candidatesList"
            item-key="PatientID"
            :single-select="singleSelect"
            @item-expanded="getSearchByRx"
          >
            <template #expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-container fluid>
                    <v-data-table
                      :headers="drugListHeaders"
                      :items="detailedMedicationsList"
                      :search="search"
                      :items-per-page="15"
                      class="table-kitchen-sink"
                      item-key="RxNumber"
                    >
                      <template
                        v-for="header in drugListHeaders.filter((header) =>
                          header.hasOwnProperty('formatter')
                        )"
                        v-slot:[`item.${header.value}`]="{ header, value }"
                      >
                        {{ header.formatter(value) }}
                      </template>
                    </v-data-table>
                  </v-container>
                </v-col>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>

      <v-tab-item :key="3">
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                offset-md="8"
                md="4"
              >
                <v-text-field
                  v-model="search"
                  :append-icon="icons.mdiMagnify"
                  label="Search"
                  single-line
                  hide-details
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-data-table
            :loading="loadingInProcess"
            class="table-kitchen-sink"
            :search="search"
            :headers="inProgressHeaders"
            item-key="_id"
            :items="inProgressItems"
            :sort-by="['modifiedAt']"
            :sort-desc="['true']"
          >
            <template
              v-for="header in inProgressHeaders.filter((header) =>
                header.hasOwnProperty('formatter')
              )"
              v-slot:[`item.${header.value}`]="{ header, value }"
            >
              {{ header.formatter(value) }}
            </template>
            <!-- action -->
            <template
              #[`item.amendInProgress`]="{ item }"
              :loading="loadingAmend"
            >
              <v-icon
                color="success"
                medium
                class="me-3"
                @click="loadPatientProfile(item['patientID'], item['_id'])"
              >
                {{ icons.mdiPencilOutline }}
              </v-icon>
            </template>
            <template #[`item.delete`]="{ item, index }">
              <v-icon
                color="error"
                medium
                class="me-3"
                @click="deleteItem(item)"
              >
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </template>

            <template v-slot:no-data>
              <v-btn
                color="primary"
                @click="initialize"
              >
                Reset
              </v-btn>
            </template>
            <!-- status -->
            <template #[`item.status`]="{item}">
              <v-chip
                color="primary"
                outlined
                pill
              >
                {{ item.status }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <!-- dialog -->
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          Are you sure you want to delete this item?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="closeDelete"
          >
            Cancel
          </v-btn>
          <v-btn
            color="success"
            @click="deleteItemConfirm"
          >
            OK
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="Deleteconfirm"
      max-width="500px"
    >
      <v-alert
        type="warning"
      >
        In Progress Medscheck Deleted Successfully
      </v-alert>
    </v-dialog>
    <v-dialog
      v-model="loadingAmend"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="pt-3">
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
    mdiCloudDownload, mdiContentDuplicate, mdiDeleteOutline, mdiMagnify, mdiPencilOutline
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import { mapGetters, mapState } from 'vuex'
import { stateService } from '@/render/api/zarya'
import { momentFormat, momentTimeZone } from '@/util/functions/index'

export default {
    name: 'MedscheckDashboard',
    setup() {
        const search = ref('')
        const currentTab = ref(0)

        return {
            search,
            currentTab,
        }
    },
    data() {
        return {
            dateFormatNoTime: 'YYYY-MM-DD',
            detailedMedicationsList: [],
            dateFormat: 'YYYY-MM-DD HH:mm',
            user: this.$auth.user.name,
            expanded: [],
            expandedPatientData: [],
            blisterMedsList: [],
            loadingAmend: false,
            refreshCandidateList: 0,
            completeTabKey: 0,
            userProfile: this.$store.getters.UserProfile,
            loading: true,
            singleExpand: true,
            loadingComplete: true,
            loadingInProcess: false,
            singleSelect: true,
            selected: [],
            selectedInProgress: [],
            stateMedscheckListById: [],
            medscheckDashboardKey: 0,
            loadingData: false,
            searchPatient: '',
            searchCandidates: '',
            stateMedscheckList: [],
            tab: 'v-step-2',
            candidatesList: [],
            isDataPulling: true,
            statusEnum: {
                COMPLETED: 'completed',
                INPROGRESS: 'progress',
            },
            candidateHeaders: [
                {
                    text: 'Last name',
                    align: 'left',
                    value: 'LastName',
                },
                {
                    text: 'First name',
                    align: 'left',
                    value: 'FirstName',
                },
                {
                    text: 'Home Phone #',
                    value: 'PhoneNumber',
                },
            ],
            inProgressHeaders: [
                {
                    text: 'Last name',
                    align: 'left',
                    value: 'LastName',
                },
                {
                    text: 'First Name',
                    align: 'left',
                    value: 'FirstName',
                },
                {
                    text: 'Amend',
                    value: 'amendInProgress',
                    align: 'center',
                },
                {
                    text: 'Patient DOB',
                    value: 'state.DATE OF BIRTH',
                },
                {
                    text: 'Date Last Saved',
                    value: 'modifiedAt',
                    sortable: true,
                    formatter: x => (x ? momentFormat(x, this.dateFormat) : null),
                },
                {
                    text: 'Medscheck Status',
                    value: 'status',
                },
                {
                    text: 'Delete',
                    value: 'delete',
                    align: 'center',
                },
            ],
            drugListHeaders: [
                {
                    text: 'Date',
                    value: 'RxDate',
                    groupable: false,
                    formatter: x => (x ? momentTimeZone(x) : null),
                },
                {
                    text: 'Brand Name',
                    value: 'BrandName',
                },
                {
                    text: 'Strength',
                    value: 'RxStrength',
                    sortable: false,
                    groupable: false,
                },
                {
                    text: 'Dr Last Name',
                    value: 'LastName',
                },
                {
                    text: 'Rx Number',
                    value: 'RxNumber',
                    groupable: false,
                },
                {
                    text: 'Rx Status',
                    value: 'Status',
                    groupable: false,
                    width: '5%',
                },
                {
                    text: 'Rx Hold',
                    value: 'hold',
                    groupable: false,
                    width: '5%',
                },
            ],
            inProgressItems: [],
            allSavedItems: [],
            completedItems: [],
            completedHeaders: [
                {
                    text: 'Last name',
                    value: 'LastName',
                },
                {
                    text: 'First Name',
                    value: 'FirstName',
                },
                {
                    text: 'Patient DOB',
                    value: 'state.DATE OF BIRTH',
                },
                {
                    text: 'Date Submitted',
                    value: 'modifiedAt',
                    sortable: true,
                    formatter: x => (x ? momentFormat(x, this.dateFormat) : null),
                },
                {
                    text: 'Medscheck Status',
                    value: 'status',
                },
                {
                    text: 'Amend',
                    value: 'amendCompleted',
                    align: 'center',
                },
                {
                    text: 'Duplicate',
                    value: 'duplicateCompleted',
                    align: 'center',
                },
                {
                    text: 'Download PDF',
                    value: 'dlPDF',
                    align: 'center',
                },
            ],

            // materio
            dialog: false,
            dialogDelete: false,
            Deleteconfirm: false,
            icons: {
                mdiDeleteOutline,
                mdiPencilOutline,
                mdiContentDuplicate,
                mdiCloudDownload,
                mdiMagnify,
            },

            editedIndex: -1,
            editedItem: {},
            defaultItem: {},
        }
    },

    watch: {
        dialog(val) {
            // eslint-disable-next-line no-unused-expressions
            val || this.close()
        },
        dialogDelete(val) {
            // eslint-disable-next-line no-unused-expressions
            val || this.closeDelete()
        },

        Deleteconfirm(val) {
            // eslint-disable-next-line no-unused-expressions
            val || this.closeDeleteConfirm()
        },
    },
    mounted() {
        this.initialState()
    },
    computed: {
        ...mapGetters(['UserProfile', 'PharmacyProfile']),
        ...mapState(['User']),
    },

    methods: {
        async searchBlisterMeds({ item }) {
            this.blisterMedsList = (await this.$root.$system.DBAdapterConn.getBlisterPackMedications(item.PatientID)) || []

            const medicationList = {}
            this.blisterMedsList.forEach((medication, index) => {
                medicationList[`med${index + 1}`] = this.blisterMedsList[index].GenericName
                medicationList[`str${index + 1}`] = this.blisterMedsList[index].Strength
                medicationList[`sig${index + 1}`] = this.blisterMedsList[index].SIGFull
                medicationList[`Brk${index + 1}`] = this.blisterMedsList[index].Brk
                medicationList[`Noon${index + 1}`] = this.blisterMedsList[index].Noon
                medicationList[`Supp${index + 1}`] = this.blisterMedsList[index].Supp
                medicationList[`Bed${index + 1}`] = this.blisterMedsList[index].Bed
            })
        },
        async getSearchByRx({ item }) {
            this.detailedMedicationsList = (await this.$root.$system.DBAdapterConn.getSingleRecentRx(
                item.PatientID,
                this.rxNumber,
                this.genericName,
                this.brandName,
                this.mixName,
                this.LastName,
                this.FirstName,
            )) || []
        },
        async initialState() {
            this.loadingData = true
            this.loading = true
            this.candidatesList = []
            this.completedItems = []
            this.inProgressItems = []
            this.stateMedscheckListById = []
            await this.loadStateInfo()
            await this.populateCandidatesList()
            this.loadingData = false
            this.loading = false
        },
        async refreshList() {
            this.loadingData = true
            this.completedItems = []
            this.inProgressItems = []
            await this.loadStateInfo()
            await this.populateCandidatesList()
            this.loadingData = false
        },

        async loadStateInfo() {
            const token = await this.$auth.getTokenSilently()
            this.pharmacyId = await this.$store.state.User.pharmacy._id

            this.stateMedscheckList = (await stateService.getStateByFormType('medscheck', this.pharmacyId, token)).map(
                this.changeFilterName,
            )
            this.filterByStatus()
            this.loadingComplete = false
            this.loadingInProcess = false
        },

        filterByStatus() {
            this.stateMedscheckList.map(medscheckItem => {
                if (medscheckItem.status == 'complete' || medscheckItem.status == 'complete-duplicate') {
                    this.completedItems.push(medscheckItem)
                } else if (medscheckItem.status == 'in-progress') {
                    this.inProgressItems.push(medscheckItem)
                }
            })
        },

        filterById(pharmacyId) {
            this.stateMedscheckList.map(medscheckItem => {
                if (medscheckItem.pharmacyID == pharmacyId) {
                    this.stateMedscheckListById.push(medscheckItem)
                } else {
                    this.stateMedscheckListById = []
                }
            })
        },

        changeFilterName(row) {
            return {
                ...row,
                FirstName: row.state['FIRST NAME'],
                LastName: row.state['LAST NAME'],
            }
        },

        saveSelectedPatientStateID(stateID) {
            this.selectedPatientStateID = stateID
        },
        openSelectedMedscheck(patient) {
            this.loadingPatient = true

            this.selectedPatientStateID = stateID
        },
        async populateCandidatesList() {
            this.candidatesList = await this.$root.$system.DBAdapterConn.getMedscheckCandidates()
            this.loading = false
        },
        async loadExpandCandidatePatientProfile() {
            this.expandedPatientData = await this.$root.$system.DBAdapterConn.getPatientData(this.expanded[0].PatientID)
            this.expanded = {
                ...this.expandedPatientData,
            }
        },

        async loadCandidatePatientProfile() {
            this.$store.dispatch('resetPatientData')
            const patientID = this.selected[0].PatientID
            const patientData = await this.$root.$system.DBAdapterConn.getPatientData(patientID)
            this.$store.dispatch('savePatientData', patientData)
            this.$root.$emit('Refresh_Table', patientID)
            this.$emit('selected-patient', patientID)
            this.$router.push({
                name: 'services-medscheck',
            })
        },
        async loadPatientProfile(patientID, stateID) {
            this.loadingAmend = true
            try {
                this.$store.dispatch('resetPatientData')
                const token = await this.$auth.getTokenSilently()
                const patientData = await stateService.getStateById(token, stateID)
                this.$store.dispatch('savePatientData', patientData)
                this.$root.$emit('Refresh_Status_Bar', patientID)
                this.$router.push({
                    name: 'services-medscheck',
                })
            } catch (err) {
                console.error(err)
            }
            this.loadingAmend = false
        },
        async duplicate(patientID, stateID) {
            const token = await this.$auth.getTokenSilently()
            const patientData = await this.$root.$system.DBAdapterConn.getPatientData(patientID)
            if (stateID) {
                patientData.stateId = stateID
            }
            const date = this.$moment().local().format('YYYY/MM/DD HH:mm:ss')
            try {
                const res = await stateService.duplicateDoc(stateID, date, token)
                this.stateMedscheckList.push(this.changeFilterName(res.data))
            } catch (err) {
                console.log(err)
            }
            this.completeTabKey += 1
            this.refreshList()
        },
        async downloadPdf(pdfRef) {
            try {
                const token = await this.$auth.getTokenSilently()
                await this.$store.dispatch('pdfDownload', {
                    token,
                    pdfRef: pdfRef.state.pdfRef,
                })
            } catch (err) {
                console.error(err)
            }
        },

        // from materio
        initialize() {
            // this.userList = JSON.parse(JSON.stringify(data))
        },

        editItem(item) {
            this.editedIndex = this.userList.indexOf(item)
            this.editedItem = {
                ...item,
            }
            this.dialog = true
        },
        deleteItem(item) {
            this.editedIndex = this.inProgressItems.indexOf(item)
            this.editedItem = {
                ...item,
            }
            this.dialogDelete = true
        },

        async deleteItemConfirm() {
            // FIXME: this.stateID should be a local variable
            this.stateID = this.inProgressItems[this.editedIndex]._id
            const token = await this.$auth.getTokenSilently()
            stateService.deleteStateById(this.stateID, token)
            this.inProgressItems.splice(this.editedIndex, 1)

            this.closeDelete()
            this.Deleteconfirm = true
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = {
                    ...this.defaultItem,
                }
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = {
                    ...this.defaultItem,
                }
                this.editedIndex = -1
            })
        },
        closeDeleteConfirm() {
            this.Deleteconfirm = false
        },

        save() {
            if (this.editedIndex > -1) {
                Object.assign(this.userList[this.editedIndex], this.editedItem)
            } else {
                this.userList.push(this.editedItem)
            }
            this.close()
        },
    },
}
</script>
